






























import { Component, Inject as VueInject } from 'vue-property-decorator'
import { AsyncComponent } from 'vue'
import { AnyObject } from '@movecloser/front-core'

import { defaultProvider, Inject, IS_MOBILE_PROVIDER_KEY, logger } from '../../../support'
import { AbstractModuleUi } from '../../abstract/ui'

import {
  SelenaFormModuleVersion,
  selenaFormModuleVersionsRegistry
} from '../SelenaForm.config'
import { SelenaFormModule } from '../SelenaForm.contracts'

import {
  ISelenaFormService, FormOptions,
  SelenaFormServiceType
} from '../../../front/shared/services/selenaForms'

/**
 * @author Kuba Fogel <kuba.fogel@movecloser.pl>
 */
@Component<SelenaFormModuleUi>({
  name: 'SelenaForm'
})
export class SelenaFormModuleUi extends AbstractModuleUi<SelenaFormModule> {
  @Inject(SelenaFormServiceType)
  protected readonly selenaFormService!: ISelenaFormService

  @VueInject({ from: IS_MOBILE_PROVIDER_KEY, default: () => defaultProvider<boolean>(false) })
  public readonly isMobileDevice!: () => boolean

  /**
   * Determines loading state.
   */
  public isLoading: boolean = false

  /**
   * Determines the state of the form.
   */
  public isSubmitted: boolean = false

  /**
   *
   */
  public errors: Array<string> = []

  /**
   * Store intercepted error from submitted form.
   */
  public error: Error | null = null

  /**
   * Options for given form type
   */
  public options: FormOptions = {}

  /**
   * Created lifecycle hook.
   */
  public async created () {
    this.options = await this.getOptions(this.version)
  }

  /**
   * Get options for template with given version.
   */
  private async getOptions (formVersion: string): Promise<FormOptions> {
    return await this.selenaFormService.getOptions(formVersion)
  }

  /**
   * Determines component version.
   */
  public get component (): AsyncComponent | undefined {
    const component = selenaFormModuleVersionsRegistry[this.version]

    if (typeof component === 'undefined') {
      logger(
        `SelenaFormModule.ui.component(): There's no Vue component associated with the [${this.data.version}] SelenaFormModuleVersion!`,
        'error'
      )
      return
    }

    return component
  }

  /**
   * Set default version of SelenaForm
   */
  public get version () {
    if (!this.data.version) {
      return SelenaFormModuleVersion.Webinar
    }

    return this.data.version as SelenaFormModuleVersion
  }

  /**
   * Reset state of form.
   */
  public resetForm () {
    this.isSubmitted = false
  }

  /**
   * Subscribes to newsletter
   */
  public async handleSubmit (event: AnyObject) {
    try {
      this.isLoading = true
      await this.selenaFormService.send(this.version, { ...event })
      this.isSubmitted = true
      this.error = null
      this.errors = []
    } catch (error) {
      logger(error, 'error')
      this.error = error as Error
    } finally {
      this.isLoading = false
    }
  }
}

export default SelenaFormModuleUi
